<script>
import { defineComponent } from "vue";
import { PolarArea } from "vue3-chart-v2";
import chartService from "../services/chartService";

export default defineComponent({
  name: "TypeChart",
  extends: PolarArea,
  mounted() {},
  data() {
    return {
      memberLists: [],
    };
  },
  method: {},
  created() {
    let labelTemp = new Array();
    let dataTemp = new Array();
    let bgColortemp = new Array();
    let backgroundColortemp = ['#F08080','#9B59B6','#2980B9','#1ABC9C','#28B463','#D4AC0D','#F39C12','#E67E22','#7F8C8D','#BB8FCE','#5D6D7E','#512E5F','#0E6251','#7B7D7D','#5B2C6F','#9A7D0A','#F8C471','#7FB3D5','#BA4A00'];

    chartService
      .getTypeList()
      .then((response) => {
        this.memberLists = response.data;

        for (var i = 0; i < this.memberLists.length; i++) {
          labelTemp.push(this.memberLists[i].keywords);
          dataTemp.push(this.memberLists[i].cnt);
           bgColortemp.push(backgroundColortemp[i]);   
        }
        this.labelsText = labelTemp;
        this.renderChart({
          labels: labelTemp,
          datasets: [
            {
              label: "",
              backgroundColor:bgColortemp,
              data: dataTemp,
            },
          ],
        });
        //this.data = dataTemp;

      })
      .catch((e) => {
        console.log(e);
      });
  },
});
</script>
