<template>
  <div>
    <div class="title">[ 일별 발생 현황 -2주간]</div>
    <div><MonthlyChart /></div>
    <div style="height: 50px;"></div>
    <div class="title">[ 팀별 발생 현황 -전체]</div>
    <div ><DeptChart /></div>
    <div style="height: 50px;"></div>
    <div class="title">[ 유형별 현황 -전체]</div>
    <div ><TypeChart /></div>    
  </div>  
</template>

<script>
import { defineComponent } from "vue";
import MonthlyChart from "./MonthlyChart.vue";
import DeptChart from "./DeptChart.vue";
import TypeChart from "./TypeChart.vue";

export default defineComponent({
  name: "App",
  components: {
    MonthlyChart,
    DeptChart,
    TypeChart,

  },
});
</script>
