<script>
import { defineComponent } from "vue";
import { Bar } from "vue3-chart-v2";
import chartService from "../services/chartService";

export default defineComponent({
  name: "MonthlyChart",
  extends: Bar,
  mounted() {},
  data() {
    return {
      memberLists: [],
    };
  },
  method: {},
  created() {
    let labelTemp = new Array();
    let dataTemp = new Array();

    chartService
      .getManageList()
      .then((response) => {
        this.memberLists = response.data;
        //console.log("list == ", this.memberLists);
        for (var i = 0; i < this.memberLists.length; i++) {
          labelTemp.push(this.memberLists[i].dt);
          dataTemp.push(this.memberLists[i].cnt);
        }
        this.labelsText = labelTemp;
        this.renderChart({
          labels: labelTemp,
          datasets: [
            {
              label: "건 수",
              backgroundColor: "#f87979",
              data: dataTemp,
            },
          ],
        });
        //this.data = dataTemp;

      })
      .catch((e) => {
        console.log(e);
      });
  },
});
</script>
