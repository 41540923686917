import http from "../http-common";

class ChartService {
  getManageList() {
    return http.post(`/chart/manage/list`);
  }   

  // getListDetail(data) {
  //   return http.post(`/chart/manage/listDetail`, data);
  // }   

  getDeptList() {    
    return http.post(`/chart/manage/listDept`);
}

getTypeList() {    
  return http.post(`/chart/manage/listtype`);
}

}
export default new ChartService();
